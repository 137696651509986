.contact {
  margin: 0 auto;
  width: 100%;
  max-width: var(--s-width);
  padding: var(--s-horizontal-padding);

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 30px;

  @media (max-width: 767px) {
    display: block;
  }
}

.title {
  grid-column: 1/3;
  margin-bottom: 0;

  @media (max-width: 767px) {
    text-align: center;
  }
}

.left {
  flex-basis: 50%;

  img {
    width: 100%;
    height: auto;
    max-width: 530px;
  }
}

.right {
  flex-basis: 50%;
  padding-top: 40px; // not mobile
}
