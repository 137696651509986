.projects {
  margin: 0 auto;
  width: 100%;
  max-width: var(--s-width);
  padding: var(--s-horizontal-padding);
  display: flex;
  flex-flow: column nowrap;

  div:nth-child(2n + 4) {
    align-self: flex-end;
  }

  button {
    align-self: center;
  }

  a {
    margin-right: 55px;
  }
}

.title {
  @media (max-width: 767px) {
    text-align: center;
  }
}
