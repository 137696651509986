html {
  box-sizing: border-box;

  font-size: 62.5%;
  font-family: 'Proxima Nova', serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
