.link {
  color: var(--c-type-0);
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  width: fit-content;
  position: relative;
  overflow: hidden;
  padding-bottom: 5px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  // TODO: Wavy Underline

  //   &:after {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     white-space: nowrap;
  //     color: transparent;
  //     text-decoration: underline;
  //     text-decoration-style: wavy;
  //     text-decoration-color: var(--c-type-0);
  //     content: attr(data-text) attr(data-text);
  //     text-underline-position: under;
  //   }
  //   &:hover:after {
  //     animation: wavy-slide 3s linear infinite;
  //   }
  // }

  // @keyframes wavy-slide {
  //   to {
  //     transform: translateX(-100px);
  //   }
}

.wrapper {
  text-decoration: none;
  display: inline-block;

  &.disabled {
    cursor: none;
    opacity: 0.6;
    pointer-events: none;
  }

  i {
    display: none;
  }
}
