.project {
  max-width: 700px;

  > img {
    width: 100%;
    height: auto;
  }

  svg {
    color: var(--c-type-0);
    vertical-align: baseline !important; // fa style overwrite
    font-size: 14px;
    margin-right: 4px;
  }
}

.links {
  white-space: nowrap;
  width: fit-content;
  display: inline-block;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: 5px;
  }
}
