.services {
  margin: 0 auto;
  width: 100%;
  max-width: var(--s-width);
  padding: var(--s-horizontal-padding);

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
    }
  }

  li {
    text-align: center;
  }
}

.title {
  font-weight: bold;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0;
  }
}
