.ctabox {
  background-color: var(--c-primary-1);
  border-radius: 15px 15px 0 15px;
  display: grid;
  grid-template-columns: 8fr 3fr;
  align-items: center;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 767px) {
    display: block;
  }
}

.body {
  padding: 0 20px;
}

.cta {
  place-self: center;
  @media (max-width: 767px) {
    text-align: center;
    margin: 5px 0 15px;
  }
}
