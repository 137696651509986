// s: section
// f: footer
// c: component

:root {
  --s-width: 1080px;
  --f-width: 940px;
  --s-horizontal-padding: 20px;

  --c-vertical-margin: 20px;

  @media (max-width: 767px) {
    --s-horizontal-padding: 10px;
  }
}
