h1 {
  font-size: 4.8rem;
  line-height: 5.8rem;
  font-weight: 800;
  color: var(--c-type-1);
}

h2 {
  font-size: 3rem;
  line-height: 3.7rem;
  font-weight: 800;
  color: var(--c-type-1);
}

h3 {
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 600;
  color: var(--c-type-0);
  margin: 1.4rem 0;
}

p {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: normal;
  color: var(--c-type-0);
}

ul {
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
  color: var(--c-type-0);

  li {
    margin-bottom: 0.5rem;
  }
}

label {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: normal;
  color: var(--c-type-1);
}

textarea {
  resize: none;
  border: none;
  background-color: var(--c-primary-1);
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
}

input {
  background-color: var(--c-primary-1);
  border: none;
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
}
