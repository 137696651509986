.spacer {
  &.xsm {
    padding-bottom: 10px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &.sm {
    padding-bottom: 25px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &.md {
    padding-bottom: 40px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &.lg {
    padding-bottom: 65px;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  &.xlg {
    padding-bottom: 90px;
    @media (max-width: 767px) {
      padding: 40px;
    }
  }
}
