.footer {
  background-color: var(--c-primary-1);
  padding: 80px 0;

  @media (max-width: 767px) {
    padding: 20px 0;
  }
}

.container {
  margin: 0 auto;
  max-width: var(--f-width);
  padding: var(--s-horizontal-padding);

  display: grid;
  grid-template-columns: 17% 34% 49%;
  align-items: start;
  justify-items: start;
  justify-content: center;

  @media (max-width: 767px) {
    display: flex;
    flex-flow: column nowrap;
  }
}

.logo {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.nav {
  padding-top: 5px;

  > a,
  > span {
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.social-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 10px;

  a {
    margin-right: 15px;
    display: block;
  }
}

.copywrite {
  padding-top: 5px;

  p {
    margin: 0;
  }
}
