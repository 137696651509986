.logo {
  width: 42px;
  height: 42px;
  display: block;

  svg {
    height: 100%;
    width: 100%;
  }
}

// Header logo only exception to brand sizing
:global {
  .header .logo {
    height: 52px;
    width: 52px;
  }
}
