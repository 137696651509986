.header {
  margin: 0 auto;
  width: 100%;
  max-width: var(--s-width);
  padding: var(--s-horizontal-padding);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: inline-block;
  img {
    max-width: 100%;
    height: auto;
  }
}

.navigation {
  ul {
    margin: 1em 0 0.5em;
    text-align: center;
  }
  li {
    display: inline;
  }
  a,
  span {
    display: inline-block;
    margin: 5px 15px;
  }
}
